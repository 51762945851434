//const apiUrl = "http://localhost:5000";
const apiUrl = "https://test.nematool.com";

export const login = async (email, password, navigate) => {
    try {
        const response = await fetch(`${apiUrl}/api/v1/auth/jwt/token/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email, password, grant_type: "password" }),
        });

        if (response.ok) {
            const data = await response.json();
            
            localStorage.setItem('authToken', data.access_token);

            navigate('/fields');

        } else {
            if (response.status === 401) {
                return "incorrect_credentials"
            }
            else{
                return "unknown_error"
            }
        }
    } catch (error) {
        console.error('Error en la petición', error);
    }
};

export const register = async(name, surname, email, password, receibeAdvertising, phone, streetNumber, city, postalCode, selectedCountry, cifNif, companyName, loyaltyProgramId, navigate) => {
    const fields = { phone, selectedCountry, postalCode, streetNumber, city, companyName, cifNif, loyaltyProgramId };
    for (const key in fields) {
        if (fields[key] === '') {
            fields[key] = null;
        }
    }
    ({ phone, selectedCountry, postalCode, streetNumber, city, companyName, cifNif, loyaltyProgramId } = fields);

    const body = {
        email,
        first_name: name,
        last_name: surname,
        password,
        receive_advertising: receibeAdvertising,
        nif: cifNif,
        company: companyName,
        loyalty_id: loyaltyProgramId,
        phone,
        street_number: streetNumber,
        city,
        zip_code: postalCode,
        country: selectedCountry
    };

    try {
        const response = await fetch(`${apiUrl}/api/v1/users/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
        });

        if (response.ok) {
            await login(email, password, navigate)
        } else {
            if(response.status === 422){
                return "email_format_invalid"
            }
            if(response.status === 409){
                return "email_exists"
            }
            if(response.status === 429){
                return "user_creation_limit"
            }
            return "unknown_error"
        }
    } catch (error) {
        console.error('Error en la petición', error);
    }
};

export const updateUser = async(id, name, surname, receibeAdvertising, phone, streetNumber, city, postalCode, selectedCountry, cifNif, companyName, loyaltyProgramId, navigate) => {
    const localtoken = localStorage.getItem('authToken');

    const fields = { id, name, surname, receibeAdvertising, phone, streetNumber, city, postalCode, selectedCountry, cifNif, companyName, loyaltyProgramId };
    let pendienteDeActualizar = true;

    for (const key in fields) {
        if (fields[key] === '' || fields[key] === null) {
            pendienteDeActualizar = true;
            break;
        } else {
            pendienteDeActualizar = false;
        }
    }

    localStorage.setItem('showUpdateUserModal', pendienteDeActualizar);

    const updateFields = { phone, selectedCountry, postalCode, streetNumber, city, companyName, cifNif, loyaltyProgramId };
    for (const key in updateFields) {
        if (updateFields[key] === '') {
            updateFields[key] = null;
        }
    }
    ({ phone, selectedCountry, postalCode, streetNumber, city, companyName, cifNif, loyaltyProgramId } = updateFields);

    const body = {
        first_name: name,
        last_name: surname,
        receive_advertising: receibeAdvertising,
        nif: cifNif,
        company: companyName,
        loyalty_id: loyaltyProgramId,
        phone,
        street_number: streetNumber,
        city,
        zip_code: postalCode,
        country: selectedCountry
    };

    try {
        const response = await fetch(`${apiUrl}/api/v1/users/${id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localtoken}`,
            },
            body: JSON.stringify(body),
        });

        if (response.ok) {
            navigate('/fields');
        } else {
            if (response.status === 422){
                return "email_in_use"
            }
            return "unknown_error"
        }
    } catch (error) {
        console.error('Error en la petición', error);
    }
};

export const rememberPassword = async(email, password, receive_advertising, navigate) => {
    const localtoken = localStorage.getItem('authToken');

    try {
        const response = await fetch(`${apiUrl}/api/v1/request_restore_code_pwa/`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${localtoken}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }),
        });

        if (response.ok) {
            const data = await response.json();
            return data
        } else {
            if(response.status === 404){
                return "email_not_found"
            }
            if(response.status === 429){
                return "limit_reached"
            }
            return "unknown_error"
        }
    } catch (error) {
        console.error('Error en la petición', error);
    }
};

export const logout = async (navigate) => {
    const localtoken = localStorage.getItem('authToken');
    if (localtoken){
        await deleteGCM();
        localStorage.removeItem('gcmToken');
        localStorage.removeItem('authToken');
        localStorage.removeItem('showUpdateUserModal');
        localStorage.removeItem('remindLaterUpdateUserTimestamp');
        localStorage.removeItem('selectedModel');
        localStorage.removeItem('activeIndex');
        navigate('/login');
    }else{
        return "Error"
    }
};

export const deleteAccount = async (navigate) => {
    try {
        const response = await fetch(`${apiUrl}/api/v1/users/me/`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('authToken')}`
            }
        });

        if (response.ok) {
            localStorage.removeItem('authToken');
            navigate('/login');
        } else {
            console.log('No se ha podido eliminar la cuenta');
        }
    } catch (error) {
        console.error('No se ha podido eliminar la cuenta:', error);
    }
};

export const getUser = async () => {
    const localtoken = localStorage.getItem('authToken');

    try {
        const response = await fetch(`${apiUrl}/api/v1/users/me/`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localtoken}`,
            },
        });

        if (response.ok) {
            const data = await response.json();
            return data
        } else {
            const errorData = await response.json();
            return errorData.error || "Error desconocido"
        }
    } catch (error) {
        return error.message || "Error de red"
    }
};

export const processGCM = async (token) => {
    const localtoken = localStorage.getItem('authToken');
    if (!token || !localtoken) {
        console.error('Token or localtoken is missing');
        return;
    }

    const browserLanguage = (navigator.language || navigator.userLanguage).split('-')[0] || 'en';

    localStorage.setItem('gcmToken', token);
    
    try {
        const response = await fetch(`${apiUrl}/api/v1/devices/gcm/${token}/profile/`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localtoken}`,
            }
        });

        const data = await response.json();

        if (data.exist){
            updateGCM(token, browserLanguage)
        }else{
            sendGCM(token, browserLanguage)
        }

    } catch (error) {
        console.error('Error sending FCM token to server:', error);
    }
};

export const sendGCM = async (token, browserLanguage) => {
    const localtoken = localStorage.getItem('authToken');
    if (!token || !localtoken) {
        console.error('Token or localtoken is missing');
        return;
    }
    
    try {
        const response = await fetch(`${apiUrl}/api/v1/devices/gcm/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localtoken}`,
            },
            body: JSON.stringify({
                registration_id: token, 
                cloud_message_type: "GCM", 
                application_id: "nematool.web",
                language: browserLanguage
            }),
        });

        if (!response.ok) {
            const errorData = await response.json();
            console.error('Error response from server:', errorData);
            throw new Error('Failed to send FCM token to server');
        }

    } catch (error) {
        console.error('Error sending FCM token to server:', error);
    }
};

export const updateGCM = async (token, browserLanguage) => {
    const localtoken = localStorage.getItem('authToken');
    if (!token || !localtoken) {
        console.error('Token or localtoken is missing');
        return;
    }
    
    try {
        const response = await fetch(`${apiUrl}/api/v1/devices/gcm/${token}/profile/`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localtoken}`,
            },
            body: JSON.stringify({
                language: browserLanguage
            })
        });

        if (response.status === 404) {
            sendGCM(token, browserLanguage)
        }

    } catch (error) {
        console.error('Error sending FCM token to server:', error);
    }
};

export const deleteGCM = async () => {
    const localtoken = localStorage.getItem('authToken');
    const token = localStorage.getItem('gcmToken');
    if (!token || !localtoken) {
        console.error('Token or localtoken is missing');
        return;
    }

    try {
        const response = await fetch(`${apiUrl}/api/v1/devices/gcm/${token}/profile/`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${localtoken}`,
            }
        });

        if (!response.ok) {
            console.error('Error deleting FCM token:', await response.text());
        }
    } catch (error) {
        console.error('Error deleting FCM token:', error);
    }
};

export const sendPushNotification = async (fieldId, title, body) => {
    try {
        const response = await fetch(`${apiUrl}/api/v1/notification/send`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json', // Indicamos que el contenido es JSON
                'Authorization': `Bearer ${localStorage.getItem('authToken')}` // Token de autenticación, si es necesario
            },
            body: JSON.stringify({ // Convertimos el objeto a JSON antes de enviarlo
                title: title,
                body: body
            }),
        });

        if (!response.ok) {
            console.error('Error enviando la notificación push:', await response.text());
        } else {
            console.log('Notificación push enviada con éxito');
        }
    } catch (error) {
        console.error('Error al enviar la notificación push:', error);
    }
};

export const restorePassword = async (hash, navigate) => {  
    try {
        const response = await fetch(`${apiUrl}/api/v1/users/restorePasswordPWA/${hash}`, {
            method: 'GET',
        });

        if (response.ok) {
            const data = await response.json();
            return data
        } else {
            if(response.status === 404){
                return "hash_not_found"
            }
            if(response.status === 410){
                return "hash_expired"
            }
            return "unknown_error"
        }

    } catch (error) {
        console.error('Error sending FCM token to server:', error);
    }
};

export const changePassword = async (hash, email, password, navigate) => {  
    try {
        const response = await fetch(`${apiUrl}/api/v1/changePassword/${hash}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json', // Indicamos que el contenido es JSON
            },
            body: JSON.stringify({ "email": email, "password": password }),
        });

        if (response.ok) {
            navigate('/login')
        } else {
            return "unknown_error"
        }

    } catch (error) {
        return "unknown_error"
    }
};