import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { PostLoginWrapper } from './PostLoginWrapper';
import { useTranslation } from 'react-i18next';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import Map from './Map';
import { isValidSerialNumber, getCrops, postField, postSensorToField } from '../actions/fieldsActions';
import { Html5Qrcode } from 'html5-qrcode';
import wizard1 from '../assets/wizard1.png';
import wizard2 from '../assets/wizard2.png';
import wizard3 from '../assets/wizard3.png';
import wizard4 from '../assets/wizard4.png';
import wizard5 from '../assets/wizard5.png';

export const CreateField = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const { t } = useTranslation();
    const [tstSerialNumber, setTstSerialNumber] = useState('');
    const [sensorId, setSensorId] = useState('');
    const [fieldName, setFieldName] = useState('');
    const [crop, setCrop] = useState('');
    const [crops, setCrops] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null); 
    const [errorMessage, setErrorMessage] = useState('');
    const [scanning, setScanning] = useState(false);
    const [html5QrCode, setHtml5QrCode] = useState(null);
    const [scanningButton, setScanningButton] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (activeIndex === 5 && document.getElementById("reader")) {
            const scanner = new Html5Qrcode("reader");
            setHtml5QrCode(scanner);

            return () => {
                if (scanner.isScanning) {
                    scanner.stop().catch((err) => console.error("Error al detener el escaneo:", err));
                }
            };
        }
        if (activeIndex === 7) {
            getCrops().then((data) => {
                setCrops(data.results);
            });
        }
    }, [activeIndex]);

    const startScan = () => {
        if (html5QrCode && !scanning) {
            setScanning(true);
            setErrorMessage('');

            const screenWidth = window.innerWidth;
            const screenHeight = window.innerHeight;

            html5QrCode.start(
                { 
                    facingMode: "environment"
                },
                {
                    fps: 10,
                    qrbox: { width: screenWidth * 0.7, height: screenWidth * 0.7 }, // Cuadro de escaneo proporcional
                    aspectRatio: screenHeight/screenWidth,
                    width: screenWidth,
                    height: screenHeight
                },
                (decodedText) => {
                    setTstSerialNumber(decodedText);
                    html5QrCode.stop()
                        .then(() => {
                            setScanning(false);
                            setScanningButton(false);
                        })
                        .catch((err) => console.error("Error al detener el escaneo:", err));
                }
            ).then(() => {
                setScanningButton(true);
            }).catch((err) => {
                console.error(`Error inicializando el escaneo: ${err}`);
                setErrorMessage('Error inicializando el escaneo. Por favor, inténtalo de nuevo.');
                setScanning(false);
                setScanningButton(false);
            });
        }
    };

    const stopScan = () => {
        if (html5QrCode && scanning) {
            html5QrCode.stop()
                .then(() => {
                    setScanning(false);
                    setScanningButton(false);
                })
                .catch((err) => console.error("Error al detener el escaneo:", err));
        }
    };

    const checkFieldName = () => {
        setErrorMessage('');
        if (fieldName.length === 0) {
            setTimeout(() => {
                setErrorMessage(t('create_field.wizard7_error'));
            }, 100);
            return false;
        }
        return true;
    }

    const checkCrop = () => {
        setErrorMessage('');
        if (crop.length === 0) {
            setTimeout(() => {
                setErrorMessage(t('create_field.wizard8_error'));
            }, 100);
            return false;
        }
        return true;
    }

    const createField = async () => {
        const fieldId = await postField(fieldName, crop, selectedLocation);
        const responseStatus = await postSensorToField(fieldId, sensorId);
        if (responseStatus !== 200){
            setErrorMessage("An error occurred while creating the field");
            return;
        }
        navigate(`/field/${fieldId}`);
    }

    const nextStep = async () => {
        if (activeIndex === 6) {
            if (!checkFieldName(tstSerialNumber)) {
                return;
            }
        }
        if (activeIndex === 7) {
            if (!checkCrop()) {
                return;
            }
        }
        if (activeIndex === 8) {
            createField();
            return;
        }
        if (activeIndex < totalSteps - 1) {
            setActiveIndex((prevIndex) => prevIndex + 1);
        }
    };

    const prevStep = () => {
        if (activeIndex > 0) {
            setActiveIndex((prevIndex) => prevIndex - 1);
        }
    };

    const validateSerialNumber = async () => {
        setErrorMessage('');
        const isValid = await isValidSerialNumber(tstSerialNumber);
        if (typeof isValid === 'string') {
            setTimeout(() => {
                setErrorMessage(t(`create_field.${isValid}`));
            }, 100);
        } else {
            let sensorId = isValid.results[0].id
            setSensorId(sensorId);
            nextStep();
        }
    };

    const handleMapLoad = useCallback((initialPosition) => {
        setSelectedLocation(initialPosition); 
    }, []);

    const handleMapClick = (position) => {
        setSelectedLocation(position); 
    };

    const totalSteps = 9;

    return (
        <PostLoginWrapper
            isCreateField={true}
            stepIndex={activeIndex}
            prevStep={prevStep}
            error={errorMessage}
        >
            <div className='flex align-items-center justify-content-center w-11 desktop-max-width' style={{ height: '75vh' }}>
                <div className='w-full' style={{ display: activeIndex === 0 ? 'flex' : 'none', flexDirection: 'column', alignItems: 'center' }}>
                    <Image src={wizard1} alt="wizard1" width={300} />
                    <p className='text-center text-xl mt-5'>{t('create_field.wizard1')}</p>
                </div>
                <div className='w-full' style={{ display: activeIndex === 1 ? 'flex' : 'none', flexDirection: 'column', alignItems: 'center' }}>
                    <Image src={wizard2} alt="wizard2" width={300} />
                    <p className='text-center text-xl mt-5'>{t('create_field.wizard2')}</p>
                </div>
                <div className='w-full' style={{ display: activeIndex === 2 ? 'flex' : 'none', flexDirection: 'column', alignItems: 'center' }}>
                    <Image src={wizard3} alt="wizard3" width={300} />
                    <p className='text-center text-xl mt-5'>{t('create_field.wizard3')}</p>
                </div>
                <div className='w-full' style={{ display: activeIndex === 3 ? 'flex' : 'none', flexDirection: 'column', alignItems: 'center' }}>
                    <Image src={wizard4} alt="wizard4" width={300} />
                    <p className='text-center text-xl mt-5'>{t('create_field.wizard4')}</p>
                </div>
                <div className='w-full' style={{ display: activeIndex === 4 ? 'flex' : 'none', flexDirection: 'column', alignItems: 'center' }}>
                    <Image src={wizard5} alt="wizard5" width={300} />
                    <p className='text-center text-xl mt-5'>{t('create_field.wizard5')}</p>
                </div>
                <div className='w-full' style={{ display: activeIndex === 5 ? 'flex' : 'none', flexDirection: 'column', alignItems: 'center' }}>
                    <p className='text-center text-xl'>{t('create_field.wizard6')}</p>
                    <InputText className='w-full' value={tstSerialNumber} onChange={(e) => setTstSerialNumber(e.target.value)} placeholder={t('create_field.wizard6_input_placeholder')} />
                    <Button className='bg-primary w-full mt-3' label={t('create_field.wizard6_button_label')} onClick={validateSerialNumber} />
                    <Button link className='text-primary font-semibold w-full mt-5 border-none text-decoration-none' label={t('create_field.wizard6_link_label')} onClick={startScan} />
                    <div id="reader" className={`reader ${scanning ? 'fullscreen' : ''}`}>
                        {scanning && (
                            <Button 
                                className="p-button-rounded p-button-danger" 
                                icon="pi pi-times" 
                                onClick={stopScan}
                                style={{
                                    position: 'absolute',
                                    top: '20px',
                                    left: '20px',
                                    zIndex: 4,
                                    visibility: scanningButton ? 'visible' : 'hidden'
                                }}
                            />
                        )}
                    </div>
                </div>
                <div className='w-full' style={{ display: activeIndex === 6 ? 'flex' : 'none', flexDirection: 'column', alignItems: 'center' }}>
                    <p className='text-center text-xl'>{t('create_field.wizard7')}</p>
                    <InputText className='w-full' placeholder={t('create_field.wizard7_input_placeholder')} onChange={(e) => setFieldName(e.target.value)} />
                    <p className='w-11 text-sm mt-2'>{t('create_field.wizard7_example')}</p>
                </div>
                <div className='w-full' style={{ display: activeIndex === 7 ? 'flex' : 'none', flexDirection: 'column', alignItems: 'center' }}>
                    <p className='text-center text-xl'>{t('create_field.wizard8')}</p>
                    <Dropdown 
                        className='w-full' 
                        options={crops} 
                        value={crop} 
                        onChange={(e) => setCrop(e.value)}
                        optionLabel="name"
                        optionValue="id"
                    />
                </div>
                <div className='w-full' style={{ display: activeIndex === 8 ? 'flex' : 'none', flexDirection: 'column', alignItems: 'center' }}>
                    <Map onMapClick={handleMapClick} onMapLoad={handleMapLoad} />
                </div>
            </div>
            <div className='w-full fixed bottom-0 left-0 w-full bg-white '>
                <div className="flex justify-content-center mb-3 w-full" style={{ position: 'relative', zIndex: 1001 }}>
                    {Array.from({ length: totalSteps }).map((_, index) => (
                        <span
                            key={index}
                            style={{
                                height: '10px',
                                width: '10px',
                                margin: '0 5px',
                                backgroundColor: activeIndex === index ? 'var(--primary-color)' : '#ccc',
                                borderRadius: '50%',
                                display: 'inline-block'
                            }}
                        ></span>
                    ))}
                </div>
            
                <div
                    className={`w-full p-3 shadow-2 flex align-items-center justify-content-center cursor-pointer ${activeIndex === 5 ? 'disabled' : ''}`}
                    style={{ zIndex: 1000, pointerEvents: activeIndex === 5 ? 'none' : 'auto' }}
                    onClick={nextStep}
                >
                    <div className="flex align-items-center gap-2 no-tap-highlight">
                        <span className="font-medium" style={{ color: activeIndex === 5 ? '#ccc' : 'var(--primary-color)' }}>
                            {activeIndex === 8 ? t('create_field.create_field') : t('create_field.next_step')}
                        </span>
                    </div>
                </div>
            </div>
        </PostLoginWrapper>
    );
};