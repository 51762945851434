import React from 'react';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { useTranslation } from 'react-i18next';

export const ModalGeneric = ({ isOpen, onClose, onConfirm, message }) => {
    const { t } = useTranslation();
    return (
        <ConfirmDialog
            className='w-11 desktop-max-width border-round-2xl'
            visible={isOpen}
            onHide={onClose}
            message={message}
            header={t('settings.advice')}
            icon="pi pi-exclamation-triangle"
            accept={onConfirm}
            reject={onClose}
            acceptLabel={t('settings.confirm')}
            rejectLabel={t('settings.cancel')}
            pt={{
                header: { className: 'border-round-top-2xl' },
                footer: {className: 'border-round-bottom-2xl'}
            }}
        />
    );
};
