import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import { PostLoginWrapper } from './PostLoginWrapper';
import { useTranslation } from 'react-i18next';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import { getUser } from '../actions/userActions';
import { isValidSerialNumber, patchSensor, getField } from '../actions/fieldsActions';
import { Html5Qrcode } from 'html5-qrcode';
import wizard5 from '../assets/wizard5.png';

export const LinkSensor = () => {
    const [activeIndex] = useState(0);
    const { t } = useTranslation();
    const [tstSerialNumber, setTstSerialNumber] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [scanning, setScanning] = useState(false);
    const [html5QrCode, setHtml5QrCode] = useState(null);
    const { fieldId } = useParams();
    const [scanningButton, setScanningButton] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const checkUser = async () => {
            const userData = await getUser();
            const user_id = userData.id;
            const field = await getField(fieldId);
            if (field == null || field.owner.id !== user_id) {
                navigate("/fields");
            }
        }

        checkUser();
        
        if (activeIndex === 0 && document.getElementById("reader")) {
            const scanner = new Html5Qrcode("reader");
            setHtml5QrCode(scanner);

            return () => {
                if (scanner.isScanning) {
                    scanner.stop().catch((err) => console.error("Error al detener el escaneo:", err));
                }
            };
        }
    }, [activeIndex]);

    const startScan = () => {
        if (html5QrCode && !scanning) {
            setScanning(true);
            setErrorMessage('');

            const screenWidth = window.innerWidth;
            const screenHeight = window.innerHeight;

            html5QrCode.start(
                { 
                    facingMode: "environment"
                },
                {
                    fps: 10,
                    qrbox: { width: screenWidth * 0.7, height: screenWidth * 0.7 }, // Cuadro de escaneo proporcional
                    aspectRatio: screenHeight/screenWidth,
                    width: screenWidth,
                    height: screenHeight
                },
                (decodedText) => {
                    setTstSerialNumber(decodedText);
                    html5QrCode.stop()
                        .then(() => {
                            setScanning(false);
                            setScanningButton(false);
                        })
                        .catch((err) => console.error("Error al detener el escaneo:", err));
                }
            ).then(() => {
                setScanningButton(true);
            }).catch((err) => {
                console.error(`Error inicializando el escaneo: ${err}`);
                setErrorMessage('Error inicializando el escaneo. Por favor, inténtalo de nuevo.');
                setScanning(false);
                setScanningButton(false);
            });
        }
    };

    const stopScan = () => {
        if (html5QrCode && scanning) {
            html5QrCode.stop()
                .then(() => {
                    setScanning(false);
                    setScanningButton(false);
                })
                .catch((err) => console.error("Error al detener el escaneo:", err));
        }
    };

    const validateSerialNumber = async () => {
        setErrorMessage('');
        const isValid = await isValidSerialNumber(tstSerialNumber);
        if (typeof isValid === 'string') {
            setTimeout(() => {
                setErrorMessage(t(`link_sensor.${isValid}`));
            }, 100);
        } else {
            let sensorId = isValid.results[0].id
            await patchSensor(sensorId, fieldId);
            navigate(`/field/${fieldId}`);
        }
    };

    return (
        <PostLoginWrapper
            isCreateField={true}
            stepIndex={activeIndex}
            error={errorMessage}
        >
            <div className='flex align-items-center justify-content-center w-11 desktop-max-width' style={{ height: '75vh' }}>
                <div className='w-full' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Image src={wizard5} alt="wizard5" width={300} />
                    <p className='text-center text-xl mt-5'>{t('link_sensor.wizard6')}</p>
                    <InputText className='w-full mt-5' value={tstSerialNumber} onChange={(e) => setTstSerialNumber(e.target.value)} placeholder={t('link_sensor.wizard6_input_placeholder')} />
                    <Button className='bg-primary w-full mt-3' label={t('link_sensor.wizard6_button_label')} onClick={validateSerialNumber} />
                    <Button link className='text-primary font-semibold w-full mt-5 border-none text-decoration-none' label={t('link_sensor.wizard6_link_label')} onClick={startScan} />
                    <div id="reader" className={`reader ${scanning ? 'fullscreen' : ''}`}>
                        {scanning && (
                            <Button 
                                className="p-button-rounded p-button-danger" 
                                icon="pi pi-times" 
                                onClick={stopScan}
                                style={{
                                    position: 'absolute',
                                    top: '20px',
                                    left: '20px',
                                    zIndex: 4,
                                    visibility: scanningButton ? 'visible' : 'hidden'
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
        </PostLoginWrapper>
    );
};