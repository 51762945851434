import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card } from 'primereact/card';
import { ProgressBar } from 'primereact/progressbar';
import { Badge } from 'primereact/badge';
import { Image } from 'primereact/image';
import { Chart } from 'primereact/chart';
import { getGuests } from '../actions/fieldsActions';
import { getColor, getColorFooter, getColorCard, getColorCardFooter } from '../utils/fieldUtils';
import { avalibleLicence, formatDateLicence, expirationDays } from '../utils/functions';
import { getTemperatureData } from '../actions/temperatureActions';
import well_signal from '../assets/well_signal_black.svg';
import half_signal from '../assets/half_signal_black.svg';
import low_signal from '../assets/low_signal_black.svg';
import not_signal from '../assets/not_signal_black.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faUserPlus, faUserXmark, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ModalShared } from './ModalShared';
import { ModalRejectShared } from './ModalRejectShared';

export const FieldCard = ({ fieldId, fieldName, sensorNumber, activeSensor, signalSensor, batterySensor, cropName, model, ownerEmail, userEmailLogin, userId, expirationLicenseDate, isFields, setTrigger }) => {
    const navigate = useNavigate();
    const [temperatureData, setTemperatureData] = useState([]);
    const { t } = useTranslation();

    let haveSensor = true;
    if (sensorNumber === 'no_sensor'){
        sensorNumber = t('field_card.no_sensor');
        haveSensor = false;
    }

    let licenceStatus = avalibleLicence(expirationLicenseDate);
    
    // Oculto la logica de las licencias
    licenceStatus = true;

    const [isSharedUsersModalOpen, setIsSharedUsersModalOpen] = useState(false);
    const [isRejectSharedModalOpen, setIsRejectSharedModalOpen] = useState(false);
    const [sharedUsers, setSharedUsers] = useState([]);

    useEffect(() => {
        const fetchTemperatureData = async () => {
            try {
                const today = new Date();
                //today.setDate(today.getDate() - 2);
                const startOfDay = new Date(today.setHours(0, 0, 0, 0)).toISOString();
                const endOfDay = new Date(today.setHours(23, 59, 59, 999)).toISOString();
                const data = await getTemperatureData(fieldId, 'H', startOfDay, endOfDay);

                // Transforma el objeto en un array de objetos con propiedades date y temperature
                const transformedData = Object.entries(data).map(([date, values]) => ({
                    date: date,
                    temperature: values.mean // Usa el valor 'mean' para la temperatura
                }));

                setTemperatureData(transformedData);
            } catch (error) {
                console.error('Error fetching temperature data:', error);
                setTemperatureData([]); // Establece un array vacío en caso de error
            }
        };

        if (model[0] === 'No model'){
            fetchTemperatureData();
        }
    }, [fieldId]);

    const formatDateChart = (date) => {
        const hour = String(date.getHours()).padStart(2, '0');
        return `${hour}`;
    };

    const temperatureChartData = {
        labels: temperatureData.map((entry) => formatDateChart(new Date(entry.date))),
        datasets: [
            {
                label: 'Temperature',
                data: temperatureData.map((entry) => entry.temperature),
                fill: false,
                borderColor: '#000000',
                pointBackgroundColor: '#000000',
                tension: 0.4,
                borderWidth: 2
            }
        ]
    };

    const temperatureChartOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            }
        },
        scales: {
            x: {
                grid: {
                    display: true
                },
                ticks: {
                    color: '#000000'
                }
            },
            y: {
                grid: {
                    display: false
                },
                ticks: {
                    color: '#000000'
                }
            }
        }
    };

    // Maneja el evento de clic en el componente
    const handleClick = () => {
        if (licenceStatus || isFields) {
            if (window.location.pathname !== `/field/${fieldId}`) {
                navigate(`/field/${fieldId}`);
            }
        }
    };

    const handleClickBuyLicense = () => {
        navigate('/create/field/license', { state: { sensorNumber } });
    };

    const handleClickLinkSensor = () => {
        navigate('/link/sensor', { state: { fieldId } });
    };

    const getSignalIcon = (signalSensor) => {
        switch (signalSensor) {
            case 'well_signal':
                return well_signal;
            case 'half_signal':
                return half_signal;
            case 'low_signal':
                return low_signal;
            default:
                return not_signal;
        }
    };

    const openSharedUsersModal = async () => {
        try {
            const response = await getGuests(fieldId);
            setSharedUsers(response.results);
            setIsSharedUsersModalOpen(true);
        } catch (error) {
            console.error("Error al obtener los usuarios compartidos:", error);
        }
    };

    const closeSharedUsersModal = () => {
        setIsSharedUsersModalOpen(false);
    };

    const openRejectSharedModal = async () => {
        try {
            setIsRejectSharedModalOpen(true);
        } catch (error) {
            console.error("Error al obtener los usuarios compartidos:", error);
        }
    };

    const closeRejectSharedModal = () => {
        setIsRejectSharedModalOpen(false);
    };

    return (
        <Card 
            className={`w-11 mt-3 ${!licenceStatus ? 'opacity-60' : ''}`}
            onClick={handleClick}
            style={{ 
                backgroundColor: 'white', 
                borderRadius: '10px',
                border: 'solid',
                borderRadius: '10px',
                borderWidth: '1px',
                borderColor: '#DEE2E6',
                boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.3)',
                maxWidth: '450px'
            }}
        >
            <div className="p-3 pb-0">
                <div className="flex justify-content-between align-items-center">
                    <div className="flex align-items-center">
                        <Badge 
                            className="flex align-items-center text-sm mr-2 bg-white border-round-3xl pl-0 text-black border-2 border-solid"
                            style={{ borderColor: '#DEE2E6', lineHeight: '1.5' }}
                            value={
                                <><span 
                                    style={{ 
                                        width: '10px', 
                                        height: '10px', 
                                        borderRadius: '50%', 
                                        marginRight: '5px',
                                        marginLeft: '5px',
                                        backgroundColor: haveSensor && activeSensor === 'true' ? '#00FF00' : '#FF0000'
                                    }}
                                ></span>
                                <span>
                                    {haveSensor ? `${t('field_card.sensor')} ${sensorNumber}` : sensorNumber}
                                </span>
                                </>
                            }
                        />
                        <span style={{ color: licenceStatus ? 'var(--primary-color)' : 'red', fontSize: '14px' }}>
                            {licenceStatus ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />} {t('field_card.license')}
                        </span>
                    </div>
                    {haveSensor && activeSensor === 'true' && (
                        <span className="flex align-items-center text-black text-sm">{t(`field_card.${signalSensor}`)} 
                            <Image src={getSignalIcon(signalSensor)} alt="signal icon" style={{ marginLeft: '5px'}} pt={{ image: { width: '20px', height: '20px' } }} />
                        </span>
                    )}
                    
                </div>

                <div className="flex align-items-center justify-content-between mt-2">
                    <h3 className="font-bold text-black mb-0 mt-0" style={{ overflowWrap: 'break-word'}}>
                        {fieldName} <span className="font-medium text-black text-sm">({cropName})</span>
                    </h3>
                    {haveSensor && activeSensor === 'true' && (
                        <span className="flex align-items-center justify-content-end text-black text-sm w-4">{t(`field_card.battery`)} 
                            {isNaN(parseInt(batterySensor)) ? batterySensor : parseInt(batterySensor)}%
                        </span>
                    )}
                </div>

                {model[0] === 'Nematode active' && licenceStatus && (
                    <div className="mb-3">
                        {model[1] === 0 && (
                            <p className="text-sm text-black mb-2 mt-0">{t('field_card.generation').charAt(0).toUpperCase() + t('field_card.generation').slice(1)} {t('field_card.initial')}</p>
                        )}
                        {model[1] !== 0 && (
                            <p className="text-sm text-black mb-2 mt-0">{model[1]}º {t('field_card.generation')}</p>
                        )}
                        <div className="mb-2">
                            <div className="flex justify-content-between align-items-center mb-2">
                                <span 
                                    className={`${model[2] > model[5] ? 'font-medium' : ''} text-black`}
                                    style={model[3] === 'red' ? { animation: 'blinker 1s linear infinite' } : {}}
                                >
                                    {t('field_card.egg')}
                                </span>
                                <span 
                                    className={`${model[2] > model[5] ? 'font-medium' : ''} uppercase text-sm text-black`}
                                    style={model[3] === 'red' ? { animation: 'blinker 1s linear infinite' } : {}}
                                >
                                    {model[3] === 'blue' ? t('field_card.wait') : (model[3] === 'orange' ? <><FontAwesomeIcon icon={faExclamationTriangle} style={{ marginRight: '5px' }} />{t('field_card.prepare')}</> : (model[3] === 'red' ? <><FontAwesomeIcon icon={faExclamationTriangle} style={{ marginRight: '5px' }} />{t('field_card.treat')}</> : t('field_card.wait')))}
                                </span>
                            </div>
                            <div style={{ padding: '3px', backgroundColor: "#DEE2E6" /* getColorFooter(model[3]) */, borderRadius: '50px' }}>
                                <ProgressBar 
                                    value={model[2] < 10 ? 10 : model[2]} 
                                    color={getColor(model[3])}
                                    showValue={true}
                                    displayValueTemplate={() => <span style={{ fontSize: '14px', fontWeight: '500'}}>{`${model[2]} %`}</span>}
                                    style={{ borderRadius: '20px', height: '20px', backgroundColor: "#DEE2E6" /* getColorFooter(model[3]) */ }}
                                    pt={{
                                        value: {
                                            className: '',
                                            style: { backgroundColor: getColor(model[3]) }
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div>
                            <div className="flex justify-content-between align-items-center mb-2">
                                <span 
                                    className={`${model[5] > model[2] ? 'font-medium' : ''} text-black`}
                                    style={model[6] === 'red' ? { animation: 'blinker 1s linear infinite' } : {}}
                                >
                                    {t('field_card.larvicide')}
                                </span>
                                <span 
                                    className={`${model[5] > model[2] ? 'font-medium' : ''} uppercase text-sm text-black`}
                                    style={model[6] === 'red' ? { animation: 'blinker 1s linear infinite' } : {}}
                                >
                                    {model[6] === 'blue' ? t('field_card.wait') : (model[6] === 'orange' ? <><FontAwesomeIcon icon={faExclamationTriangle} style={{ marginRight: '5px' }} />{t('field_card.prepare')}</> : (model[6] === 'red' ? <><FontAwesomeIcon icon={faExclamationTriangle} style={{ marginRight: '5px' }} />{t('field_card.treat')}</> : t('field_card.wait')))}
                                </span>
                            </div>
                            <div style={{ padding: '3px', backgroundColor: "#DEE2E6" /* getColorFooter(model[6]) */, borderRadius: '50px' }}>
                                <ProgressBar 
                                    value={model[5] < 10 ? 10 : model[5]} 
                                    color={getColor(model[6])}
                                    showValue={true}
                                    displayValueTemplate={() => <span style={{ fontSize: '14px', fontWeight: '500'}}>{`${model[5]} %`}</span>}
                                    style={{ borderRadius: '20px', height: '20px', backgroundColor: "#DEE2E6" /* getColorFooter(model[6]) */ }}
                                    pt={{
                                        value: {
                                            className: '',
                                            style: { backgroundColor: getColor(model[6]) }
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                )}

                {model[0] === 'Solarization active' && licenceStatus && (
                    <div className="mb-3">
                        {/* Encabezado con el título y valor actual */}
                        <div className="flex justify-content-between align-items-center mb-2">
                            <span className="font-medium text-black">{t('field_card.degrees')}</span>
                            <span className="font-medium uppercase text-black">{
                                model[1] < 50 ? t('field_card.null') : 
                                model[1] < 500 ? t('field_card.low') : 
                                model[1] < 1500 ? t('field_card.moderate') : 
                                model[1] < 3000 ? t('field_card.good') : 
                                model[1] < 6000 ? t('field_card.excellent') :
                                t('field_card.super')
                            }</span>
                        </div>
                        <div style={{ padding: '3px', backgroundColor: "#DEE2E6" /* getColorCardFooter(model) */ , borderRadius: '50px'}}>
                            <ProgressBar 
                                value={model[1] < 100 ? 13 : 
                                    model[1] < 1000 ? 16 : 
                                    model[1] < 1333 ? 19 : 
                                    model[1] < 1500 ? 21 :
                                    model[1] < 1777 ? 23 :
                                    model[1] < 2000 ? 25 : 
                                    model[1] > 7000 ? 100 :
                                    (model[1] / 7000) * 100} 
                                color={getColor(model[3])}
                                showValue={true}
                                displayValueTemplate={() => <span style={{ fontSize: '14px', fontWeight: '500', backgroundColor: getColorCard(model) }}>{`${model[1]} ºC`}</span>}
                                style={{ borderRadius: '20px', height: '20px', backgroundColor: "#DEE2E6" /* getColorCardFooter(model) */ }}
                                pt={{
                                    value: {
                                        className: '',
                                        style: { backgroundColor: getColorCard(model) }
                                    }
                                }}
                            />
                        </div>
                    </div>
                )}

                {model[0] === 'No model' && (
                    temperatureData.length > 0 ? (
                        <Chart className="z-index-1" type="line" data={temperatureChartData} options={temperatureChartOptions} />
                    ) : (
                        <p className="text-black text-center">{t('field_card.no_data_available')}</p>
                    )
                )}

            </div>
            <div 
                className="p-3 pt-2 pb-2"
                style={{ backgroundColor: "#DEE2E6", borderRadius: '0 0 10px 10px' }}
            >
                <span 
                    className="text-sm m-0 text-black" 
                    style={{ wordWrap: 'break-word', overflowWrap: 'break-word'}}
                >
                       
                {userEmailLogin === ownerEmail ? 
                    <>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span style={{ wordBreak: 'break-all' }}>{t('field_card.owner')}: {t('field_card.you')}</span>
                            {/*<FontAwesomeIcon 
                                icon={faUserPlus} 
                                style={{ 
                                    color: 'black', 
                                    fontSize: '16px', 
                                    cursor: 'pointer',
                                    marginLeft: '10px' 
                                }} 
                                onClick={(e) => {
                                    e.stopPropagation();
                                    openSharedUsersModal();
                                }}
                            />*/}
                    </div>
                    <div>
                        {/* <span style={{ cursor: 'pointer' }} onClick={(e) => { e.stopPropagation(); navigate('/license'); }}>{t('field_card.licence_deactivated')}</span> */}
                        <span style={{ cursor: 'pointer', textDecoration: 'underline', whiteSpace: 'nowrap' }} onClick={(e) => { e.stopPropagation(); openSharedUsersModal(); }}>{t('field_card.link')}</span> 
                    </div>
                    </>
                : (
                    <>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span style={{ wordBreak: 'break-all' }}>{t('field_card.owner')}: {ownerEmail}</span>
                            {/*
                            <FontAwesomeIcon 
                                icon={faUserXmark} 
                                style={{ 
                                    color: 'black', 
                                    fontSize: '16px', 
                                    cursor: 'pointer',
                                    marginLeft: '10px' 
                                }} 
                                onClick={(e) => {
                                    e.stopPropagation();
                                    openRejectSharedModal();
                                }}
                            />
                            */}
                    </div>
                    <div style={{ display: 'flex'}}>
                        <span style={{ cursor: 'pointer', textDecoration: 'underline', whiteSpace: 'nowrap' }} onClick={(e) => { e.stopPropagation(); openRejectSharedModal(); }}>{t('field_card.unlink')}</span> 
                    </div>
                    </>
                )}
                </span>
            </div>

            <ModalShared 
                isOpen={isSharedUsersModalOpen} 
                onClose={closeSharedUsersModal} 
                sharedUsers={sharedUsers}
                fieldId={fieldId}
                setSharedUsers={setSharedUsers}
            />

            <ModalRejectShared 
                isOpen={isRejectSharedModalOpen} 
                onClose={closeRejectSharedModal}
                fieldId={fieldId}
                userId={userId}
                setTrigger={setTrigger}
            />
        </Card>
    );
};