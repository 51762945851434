import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Importa useNavigate
import { fetchNotifications, markNotificationsAsRead } from '../actions/fieldsActions';
import { PostLoginWrapper } from './PostLoginWrapper';
import { Card } from 'primereact/card';
import { useTranslation } from 'react-i18next';

export const Notifications = () => {
    const [notifications, setNotifications] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const fieldId = useLocation().state.fieldId;

    const navigate = useNavigate();
    const { t } = useTranslation();
    const notificationListRef = useRef(null);

    const CARD_WIDTH = 450;
    const containerWidth = notificationListRef.current ? notificationListRef.current.offsetWidth : 0;
    const cardsPerRow = Math.floor(containerWidth / CARD_WIDTH);

    useEffect(() => {
        const abortController = new AbortController();
        const getNotifications = async () => {
            setIsLoading(true);
            await fetchNotifications(fieldId, setNotifications, abortController.signal);
            setIsLoading(false);
        };

        getNotifications();

        return () => {
            abortController.abort();
        };
    }, []);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const formattedDate = `${day}/${month}/${year} at ${hours}:${minutes}`;
        return formattedDate;
    };

    const paddedNotifications = [...notifications];
    const remainder = notifications.length % cardsPerRow;

    if (remainder !== 0) {
        const emptyCardsNeeded = cardsPerRow - remainder;
        for (let i = 0; i < emptyCardsNeeded; i++) {
            paddedNotifications.push({ id: `empty-${i}`, isEmpty: true });
        }
    }

    return (
        <PostLoginWrapper isNotifications={!fieldId}>
            <div
                className='w-full mt-4 desktop-notifications-list mobile-notifications-list'
                style={{ display: 'flex', alignItems: 'center' }}
                ref={notificationListRef}
            >
                {isLoading ? (
                    <div style={{ marginTop: '135px' }} className="loading"></div>
                ) : (
                    paddedNotifications.map((notification, index) => {
                        if (notification.isEmpty) {
                            return <div key={notification.id} style={{ width: CARD_WIDTH, visibility: 'hidden', height: '0px' }} />;
                        }

                        return (
                            <Card
                                key={index}
                                style={{ 
                                    backgroundColor: !fieldId && notification.read ? '#f0f0f0' : 'white',
                                    borderRadius: '10px',
                                    border: 'solid',
                                    borderRadius: '10px',
                                    borderWidth: '1px',
                                    borderColor: '#DEE2E6',
                                    boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.3)',
                                    maxWidth: '450px'
                                }}
                                onClick={async () => {
                                    if (!fieldId) {
                                        await markNotificationsAsRead();
                                        navigate(`/field/${notification.field_id}`);
                                    }
                                }} 
                                className='w-11 mb-3 p-3'
                            >
                                <p className='font-medium text-color-secondary mt-0'>
                                    {t('notifications.field')} {notification.field_name}: {t(notification.type.includes('alert') ? 'notifications.treatment' : 'notifications.preparation')}
                                </p>
                                <p className='font-medium text-color-secondary'>{formatDate(notification.created)}</p>
                                <p className='text-color-secondary mb-0'>{t(`notifications.${notification.type}`)}</p>
                            </Card>
                        );
                    })
                )}
            </div>
        </PostLoginWrapper>
    );
};
