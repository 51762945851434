import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Importa useNavigate
import { fetchNotifications } from '../actions/fieldsActions';
import { PostLoginWrapper } from './PostLoginWrapper';
import { Card } from 'primereact/card';
import { useTranslation } from 'react-i18next';

export const Notifications = () => {
    const [notifications, setNotifications] = useState([]);
    const [loading, setLoading] = useState(true); // Estado para el loading
    const fieldId = useLocation().state.fieldId;

    const navigate = useNavigate(); // Inicializa el hook useNavigate
    const { t } = useTranslation();
    useEffect(() => {
        const abortController = new AbortController();
        const getNotifications = async () => {
            setLoading(true);
            await fetchNotifications(fieldId, setNotifications, abortController.signal);
            setLoading(false);
        };

        getNotifications();

        return () => {
            abortController.abort();
        };
    }, []);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const formattedDate = `${day}/${month}/${year} at ${hours}:${minutes}`;
        return formattedDate;
    };

    return (
        <PostLoginWrapper>
            <div className='w-11'>
                <h3 className='text-xl font-bold text-color-secondary mb-3 text-center'>{t('notifications.title')}</h3>
                {notifications.map((notification, index) => (
                    notification.event.type && (
                        <Card
                            key={index}
                            onClick={() => navigate(`/field/${notification.event.field_id}`)} // Usa navigate
                            className='mb-3 p-3'
                        >
                            <p className='font-medium text-color-secondary mt-0'>
                                {t('notifications.field')} {notification.event.field_name}: {t(notification.event.type.includes('alert') ? 'notifications.treatment' : 'notifications.preparation')}
                            </p>
                            <p className='font-medium text-color-secondary'>{formatDate(notification.event.created_original)}</p>
                            <p className='text-color-secondary mb-0'>{t(`notifications.${notification.event.type}`)}</p>
                        </Card>
                    )
                ))}
            </div>
        </PostLoginWrapper>
    );
};
