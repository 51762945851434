import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getField, getCrops, deleteField, getGuests, deleteGuest } from '../actions/fieldsActions';
import { getUser } from '../actions/userActions';
import { postNematode, postSolarization, putNematode, putSolarization } from '../actions/modelActions'; 
import { getSignalStatus, getModel, getModelId, getOwner, getColor, getColorFooter, getColorCard, getColorCardFooter } from '../utils/fieldUtils';
import { ModalGeneric } from './ModalGeneric';
import { ModalInterpolate } from './ModalInterpolate';
import { avalibleLicence, formatDateLicence, expirationDays } from '../utils/functions';
import Map from './Map';
import { Calendar } from 'primereact/calendar';
import { Chart } from 'primereact/chart';
import { getTemperatureData, getPredictionModel, getPredictionModelJ2, getSolarizationData, getExcelPredictionModel, getExcelSolarizationData, getExcelTemperatureData } from '../actions/temperatureActions';
import 'chartjs-plugin-gradient'; // Importa el plugin
import { PostLoginWrapper } from './PostLoginWrapper';
import { useTranslation } from 'react-i18next';
import { Badge } from 'primereact/badge';
import { Image } from 'primereact/image';
import { ProgressBar } from 'primereact/progressbar';
import { InputSwitch } from 'primereact/inputswitch';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import well_signal from '../assets/well_signal_black.svg';
import half_signal from '../assets/half_signal_black.svg';
import low_signal from '../assets/low_signal_black.svg';
import not_signal from '../assets/not_signal_black.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faDownload, faMapMarkerAlt, faTrash, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

export const Field = () => {
    const { fieldId } = useParams();
    const navigate = useNavigate(); 
    const [errorMessage, setErrorMessage] = useState('');
    const [fieldData, setFieldData] = useState(null);
    const [crop, setCrop] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userId, setUserId] = useState('');
    const [haveModel, setHaveModel] = useState('');
    const [isInterpolateModalOpen, setIsInterpolateModalOpen] = useState(false);
    const [initDate, setInitDate] = useState(null);
    const [lastDate, setLastDate] = useState(new Date());
    const [chartData, setChartData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingInit, setIsLoadingInit] = useState(true);
    const { t } = useTranslation();
    const [refreshTrigger, setRefreshTrigger] = useState(false);
    const [isLoadingTrigger, setIsLoadingTrigger] = useState(false);
    const [isMapDialogVisible, setIsMapDialogVisible] = useState(false);
    const [showStartModelDialog, setShowStartModelDialog] = useState(false);
    const [showStopNematodeDialog, setShowStopNematodeDialog] = useState(false);
    const [showStopSolarizationDialog, setShowStopSolarizationDialog] = useState(false);
    const [showDeleteFieldDialog, setShowDeleteFieldDialog] = useState(false);
    const [showDownloadDataDialog, setShowDownloadDataDialog] = useState(false);
    const [currentModel, setCurrentModel] = useState(null);

    useEffect(() => {
        getField(fieldId).then((field) => {
            if (field == null) {
                navigate("/fields");
            }
        })
        const fetchUserData = async () => {
            try {
                const userData = await getUser();
                setUserEmail(userData.email);
                setUserId(userData.id);
                if (fieldId) {
                    const fieldData = await getField(fieldId);
                    setFieldData(fieldData);
                    const cropsData = await getCrops();
                    const cropData = cropsData.results.find(crop => crop.id === fieldData.crop);
                    setCrop(cropData.id);
                    setIsLoadingInit(false);
                } else {
                    console.error("El fieldId es undefined");
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
            }
        };

        fetchUserData();
    }, [fieldId, refreshTrigger]);

    useEffect(() => {
        let newDateInit = new Date();
        
        if (fieldData) {
            if(fieldData.active_prediction_model){
                //newDateInit = new Date(fieldData.active_prediction_model.started);
                newDateInit.setDate(newDateInit.getDate() - 7);
                setHaveModel('N');
            }
            else if(fieldData.active_solarization){
                //newDateInit = new Date(fieldData.active_solarization.started);
                newDateInit.setDate(newDateInit.getDate() - 7);
                setHaveModel('S');
            }
            else{
                newDateInit.setDate(newDateInit.getDate() - 7);
                setHaveModel('No model');
            }
            
            setInitDate(newDateInit);
            setIsLoading(true);
            const timer = setTimeout(() => {
                fetchChartData(fieldData.id, getModelId(fieldData), newDateInit, lastDate);
            }, 1000);
            
            return () => clearTimeout(timer);
        }
    }, [fieldData]);

    const f = fieldData;

    let licenseStatus;
    if (f) {
        licenseStatus = avalibleLicence(f.expiration_license_date);
        licenseStatus = true;
    }

    let lat, lon;
    if (f && f.area && Array.isArray(f.area.coordinates) && f.area.coordinates.length === 2) {
        lon = f.area.coordinates[0];
        lat = f.area.coordinates[1];
    } else {
        lat = 0;
        lon = 0;
    }
    const ownerEmail = f ? getOwner(f) : '';

    const handleStartModel = () => {
        setShowStartModelDialog(true);
    };

    const handleStartNematode = async () => {
        if (f) {
            await postNematode(f.id);
            setIsInterpolateModalOpen(true);
            setIsLoadingTrigger(true);
        }
    };

    const handleStartSolarization = async () => {
        if (f) {
            await postSolarization(f.id);
            setIsLoadingTrigger(true);
            setRefreshTrigger(prev => !prev);
        }
    };

    const handleStopNematode = () => {
        setShowStopNematodeDialog(true);
    };

    const handleStopSolarization = () => {
        setShowStopSolarizationDialog(true);
    };

    const handleDeleteField = () => {
        setShowDeleteFieldDialog(true);
    };

    const handleMapLoad = (position) => {
        // Puedes realizar otras acciones cuando el mapa se carga
    };

    const handleClickBuyLicense = (sensorNumber) => {
        navigate('/create/field/license', { state: { sensorNumber } });
    };

    const handleClickLinkSensor = () => {
        navigate('/link/sensor', { state: { fieldId } });
    };

    const closeInterpolateModal = () => {
        setIsInterpolateModalOpen(false);
        setRefreshTrigger(prev => !prev);
    };

    const formatDateSensor = (date) => {
        const dateSensor = new Date(date.replace('Z', ''));
        let now = new Date(new Date().toLocaleString('en-US', { timeZone: 'GMT' }));

        const diffMs = now - dateSensor;
        const diffMinutes = Math.floor(diffMs / (1000 * 60));
        const diffHours = Math.floor(diffMinutes / 60);
        const diffDays = Math.floor(diffHours / 24);

        if (diffDays > 0) {
            return `${diffDays} días`;
        } else if (diffHours > 0) {
            return `${diffHours} horas`;
        } else {
            return `${diffMinutes} minutos`;
        }
    };

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const formatDateChart = (date, interval) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hour = String(date.getHours()).padStart(2, '0');
        if (interval === 'H') {
            return `${day}/${month} ${hour}:00`;
        } else {
            return `${day}/${month}`;
        }
    };

    const fetchChartData = async (fieldId, model, initDate, lastDate) => {
        setIsLoading(true);
        const formattedInitDate = formatDate(initDate);
        const nextDay = new Date(lastDate);
        nextDay.setDate(nextDay.getDate() + 1);
        const formattedLastDate = formatDate(nextDay);
        let modelName = t('field.solarization');
        let modelNameJ2 = "";
        let modelData = [];
        let modelDataJ2 = [];
        let interval = 'H';
        if (model && model[0] === "N"){
            interval = "D";
            modelName = t('field.ovicide');
            modelNameJ2 = t('field.larvicide');
        }

        const temperatureData = await getTemperatureData(fieldId, interval, formattedInitDate, formattedLastDate);
        const labels = Object.keys(temperatureData).map(dateString => formatDateChart(new Date(dateString), interval));
        const temperatureDataset = Object.values(temperatureData).map(entry => entry.mean);

        let modelDataset = [];
        let modelDatasetJ2 = [];
        let modelDatasetJ2Color = [];
        if (model && model[0] === "N"){  
            modelData = await getPredictionModel(model[1], formattedInitDate, formattedLastDate);
            modelDataJ2 = await getPredictionModelJ2(model[1], formattedInitDate, formattedLastDate);
            modelDataset = null;
            if (modelData && modelData.length > 0){
                modelDataset = labels.map(label => {
                    const modelEntry = modelData.find(entry => formatDateChart(new Date(entry.date), interval) === label);
                    return modelEntry ? modelEntry.ratio_eggs * 100 : null;
                });
            }
            modelDatasetJ2 = labels.map(label => {
                const modelEntry = modelDataJ2.find(entry => formatDateChart(new Date(entry.date), interval) === label);
                return modelEntry ? modelEntry.ratio_larvicide : null;
            });
            modelDatasetJ2Color = labels.map(label => {
                const modelEntry = modelDataJ2.find(entry => formatDateChart(new Date(entry.date), interval) === label);
                return modelEntry ? modelEntry.color : null;
            });
        }
        else if (model && model[0] === "S"){
            modelData = await getSolarizationData(fieldId, formattedInitDate, formattedLastDate);
            modelDataset = labels.map(label => {
                const modelEntry = modelData.find(entry => formatDateChart(new Date(entry.date), interval) === label);
                return modelEntry ? modelEntry.degrees : null;
            });
        }

        const getColor = (value) => {
            if (model && model[0] === "S"){
                return '#8AE640';
            }

            if (value == null) return 'transparent';
            if(value > 130) return '#42A5F5';
            if(value > 100) return '#E03642';
            if(value > 80) return '#F9794A';

            if(value == 'blue') return '#67AAFB';
            if(value == 'green') return '#7ECA22';
            if(value == 'orange') return '#F6894E';
            if(value == 'red') return '#ED5F55';

            return '#8AE640';
        };

        const datasets = [
            {
                label: t('field.temperature'),
                fill: false,
                borderColor: '#8A99AD',
                tension: .4,
                data: temperatureDataset,
                yAxisID: 'y',
                backgroundColor: 'transparent',
                pointBackgroundColor: '#8A99AD',
                pointBorderColor: '#8A99AD',
                pointRadius: 2,
                pointHoverRadius: 5,
                borderWidth: 2,
            }
        ];

        if (model && modelData.length > 0) {
            let ultimoValor = modelDataset[modelDataset.length - 1];
            let colorUltimo = getColor(ultimoValor);
            datasets.push({
                label: modelName,
                borderColor: colorUltimo,
                fill: false,
                tension: .4,
                data: modelName === 'Ovicida' ? modelDataset.map(value => (value > 100 ? 100 : value)) : modelDataset.map(value => (value > 7000 ? 7000 : value)),
                yAxisID: 'y1',
                pointBackgroundColor: modelDataset.map(value => getColor(value)),
                pointBorderColor: modelDataset.map(value => getColor(value)),
                pointRadius: 2,
                pointHoverRadius: 5,
                borderWidth: 2,
                segment: {
                    borderColor: ctx => getColor(modelDataset[ctx.p1DataIndex]),
                    backgroundColor: ctx => `${getColor(modelDataset[ctx.p1DataIndex])}33`
                }
            });

            if (model[0] === "N"){
                let ultimoValorJ2 = modelDatasetJ2Color[modelDatasetJ2Color.length - 1];
                let colorUltimoJ2 = getColor(ultimoValorJ2);
                datasets.push({
                    label: modelNameJ2,
                    borderColor: colorUltimoJ2,
                    fill: false,
                    tension: .4,
                    data: modelDatasetJ2.map(value => (value > 100 ? 100 : value)),
                    yAxisID: 'y1',
                    pointBackgroundColor: modelDatasetJ2Color.map(value => getColor(value)),
                    pointBorderColor: modelDatasetJ2Color.map(value => getColor(value)),
                    pointRadius: 2,
                    pointHoverRadius: 5,
                    borderWidth: 2,
                    segment: {
                        borderColor: ctx => getColor(modelDatasetJ2Color[ctx.p1DataIndex]),
                        backgroundColor: ctx => `${getColor(modelDatasetJ2Color[ctx.p1DataIndex])}33`
                    },
                    hidden: true
                });
            }
        }

        setChartData({
            labels,
            datasets
        });
        setIsLoading(false);
        setIsLoadingTrigger(false);
    };

    const getLightTheme = () => {
        let multiAxisOptions = {
            animation: {
                duration: 0 // Desactiva la animación
            },
            stacked: false,
            maintainAspectRatio: false,
            aspectRatio: .6,
            plugins: {
                legend: {
                    labels: {
                        usePointStyle: true,
                        boxWidth: 10,
                        boxHeight: 10,
                        padding: 10,
                        color: '#000000',
                        pointStyle: 'circle',
                        generateLabels: (chart) => {
                            const datasets = chart.data.datasets;
                            return datasets.map((dataset, i) => ({
                                text: dataset.label,
                                fillStyle: dataset.borderColor,
                                strokeStyle: dataset.borderColor,
                                lineWidth: 2,
                                hidden: !chart.isDatasetVisible(i),
                                index: i
                            }));
                        }
                    },
                    onClick: (e, legendItem, legend) => {
                        const index = legendItem.index;
                        const ci = legend.chart;
                        const meta = ci.getDatasetMeta(index);

                        if (legendItem.text === t('field.temperature') || legendItem.text === t('field.solarization')) {
                            meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;
                        } else {
                            const datasetMeta1 = ci.getDatasetMeta(1);
                            const datasetMeta2 = ci.getDatasetMeta(2);
                            if (index === 2) {
                                datasetMeta1.hidden = true;
                                datasetMeta2.hidden = false;
                            }
                            if (index === 1) {
                                datasetMeta1.hidden = false;
                                datasetMeta2.hidden = true;
                            }
                        }

                        ci.update();
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    type: 'linear',
                    display: true,
                    position: 'left',
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    },
                    title: {
                        display: true,
                        text: t('field.temperature_axis'),
                        color: '#495057'
                    }
                },
                ...(haveModel!= "No model" ? {
                    y1: {
                        beginAtZero: true,
                        type: 'linear',
                        display: true,
                        position: 'right',
                        min: 0,
                        max: haveModel === "N" ? 105 : 7700,
                        ticks: {
                            ...(haveModel === "N" ? {
                                stepSize: 5,
                                callback: (value) => {
                                    if (value <= 100) {
                                        return value;
                                    }
                                    return '';
                                }
                            } : {
                                stepSize: 700,
                                callback: (value) => {
                                    if (value <= 7000) {
                                        return value;
                                    }
                                    return '';
                                }
                            }),
                            color: '#495057',
                        },
                        grid: {
                            drawOnChartArea: false,
                            color: '#ebedef'
                        },
                        title: {
                            display: true,
                            text: haveModel === "N" ? t('field.model_nematode_axis') : t('field.model_solarization_axis'),
                            color: '#495057'
                        }
                    }
                } : {})
            }
        };

        return {
            multiAxisOptions
        }
    }
    const { multiAxisOptions } = getLightTheme();

    const downloadData = async (model) => {
        setCurrentModel(model);
        setShowDownloadDataDialog(true);
    };

    const downloadModelData = async (model) => {
        let response;
        const timezoneOffset = new Date().getTimezoneOffset() * -1 / 60;
        if (model && model[0] === "Nematode active"){
            response = await getExcelPredictionModel(f.id, formatDate(initDate), formatDate(lastDate), timezoneOffset);
        }
        else if (model && model[0] === "Solarization active"){
            response = await getExcelSolarizationData(f.id, formatDate(initDate), formatDate(lastDate), timezoneOffset);
        }
        else{
            return;
        }
        const url = window.URL.createObjectURL(await response.blob());
        const a = document.createElement('a');
        a.href = url;
        a.download = 'data-model.xlsx';
        document.body.appendChild(a);
        a.click();
        a.remove();
    };

    const downloadTemperatureData = async (model) => {
        let interval = 'H';
        const timezoneOffset = new Date().getTimezoneOffset() * -1 / 60;
        if (model && model[0] === "Nematode active"){
            interval = 'D';
        }
        const response = await getExcelTemperatureData(f.id, interval, formatDate(initDate), formatDate(lastDate), timezoneOffset);
        const url = window.URL.createObjectURL(await response.blob());
        const a = document.createElement('a');
        a.href = url;
        a.download = 'data-temperature.xlsx';
        document.body.appendChild(a);
        a.click();
        a.remove();
    };

    const handleOpenMaps = () => {
        if (lat && lon) {
            const url = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lon}`;
            window.open(url, '_blank');
        } else {
            console.error("Coordenadas no disponibles");
        }
    };

    const getSignalIcon = (signalSensor) => {
        switch (signalSensor) {
            case 'well_signal':
                return well_signal;
            case 'half_signal':
                return half_signal;
            case 'low_signal':
                return low_signal;
            default:
                return not_signal;
        }
    };

    let sensorNumber = f && f.sensors.length > 0 ? f.sensors[0].tst_serial_number : 'no_sensor';

    let haveSensor = true;
    if (sensorNumber === 'no_sensor'){
        sensorNumber = t('field_card.no_sensor');
        haveSensor = false;
    }

    let fieldName = f ? f.name : '';
    let activeSensor = f && f.sensors.length > 0 && f.sensors[0].is_activated ? 'true' : 'false';
    let signalSensor = f && f.sensors.length > 0 ? getSignalStatus(f.sensors[0].current_rssi) : 'no_signal';
    let batterySensor = f && f.sensors.length > 0 ? f.sensors[0].current_battery : 'no_battery';

    let isOwner = userEmail === ownerEmail;
    let licenceStatus = true;

    let model = f && getModel(f);

    const handleMapIconClick = () => {
        setIsMapDialogVisible(true);
    };

    const handleCloseMapDialog = () => {
        setIsMapDialogVisible(false);
    };

    return (
        <><ConfirmDialog />
            <PostLoginWrapper isField={true} fieldId={fieldId} error={errorMessage}>
                <div className='flex flex-column align-items-center w-11'>
                    {isLoadingInit ? (
                        <div className='flex justify-content-center'>
                            <div className="loading"></div>
                        </div>
                    ) : (
                        <>
                            {f && (
                                <>
                                    <div className='w-full desktop-max-width'>
                                        <div className="flex justify-content-between align-items-center mt-5">
                                            <div className="flex align-items-center">
                                                <Badge
                                                    className="flex align-items-center text-sm mr-2 bg-white border-round-3xl pl-0 text-black border-2 border-solid"
                                                    style={{ borderColor: '#DEE2E6', lineHeight: '1.5', cursor: 'pointer'  }}
                                                    value={<><span
                                                        style={{
                                                            width: '10px',
                                                            height: '10px',
                                                            borderRadius: '50%',
                                                            marginRight: '5px',
                                                            marginLeft: '5px',
                                                            backgroundColor: haveSensor && activeSensor === 'true' ? '#00FF00' : '#FF0000'
                                                        }}
                                                    ></span>{haveSensor ? `${t('field_card.sensor')} ${sensorNumber}` : sensorNumber}</>}
                                                    onClick={() => {
                                                        if (isOwner) {
                                                            navigate(`/link/sensor/${f.id}`);
                                                        }
                                                    }}
                                                />
                                                <span style={{ color: licenceStatus ? 'var(--primary-color)' : 'red', fontSize: '14px' }}>
                                                    {licenceStatus ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />} {t('field.license')}
                                                </span>
                                            </div>
                                            {haveSensor && activeSensor === 'true' && (
                                                <span className="flex align-items-center text-color-secondary text-sm">{t(`field_card.${signalSensor}`)}
                                                    <Image src={getSignalIcon(signalSensor)} alt="signal icon" style={{ marginLeft: '5px' }} pt={{ image: { width: '20px', height: '20px' } }} />
                                                </span>
                                            )}
                                        </div>
                                        <div className="flex justify-content-between align-items-start mt-2 mb-1">
                                            <h3 className="font-bold text-color-secondary mb-0 mt-0" style={{ overflowWrap: 'break-word'}}>{fieldName}</h3>
                                            {haveSensor && activeSensor === 'true' && (
                                                <span style={{ marginTop: '4px' }} className="flex align-items-center justify-content-end text-color-secondary text-sm w-4">{t(`field_card.battery`)}
                                                    {isNaN(parseInt(batterySensor)) ? batterySensor : parseInt(batterySensor)}%
                                                </span>
                                            )}
                                        </div>
                                        <div className="flex align-items-center mt-0 mb-1">
                                            <span className="font-medium text-color-secondary text-sm">({t(`field.crops.${crop}`)})</span>
                                            <div style={{ display: 'flex', alignItems: 'center', marginLeft: '5px', cursor: 'pointer' }} onClick={() => handleMapIconClick()}>
                                                <FontAwesomeIcon
                                                    icon={faMapMarkerAlt}
                                                    style={{ marginRight: '5px', color: 'var(--primary-color)' }} />
                                                <span className="font-medium text-primary text-sm underline">{t(`field.map`)}</span>
                                            </div>
                                        </div>
                                        <div className='flex align-items-center justify-content-between mt-2 mb-1'>
                                            {haveModel === 'N' ? (
                                                <>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <span style={{ marginRight: '10px' }}>{t('field.nematode_model')}</span>
                                                        <InputSwitch
                                                            style={{ transform: 'scale(0.8)' }}
                                                            checked={true}
                                                            onChange={(e) => handleStopNematode(e.value)}
                                                            disabled={!isOwner} />
                                                    </div>
                                                    <FontAwesomeIcon
                                                        icon={faTrash}
                                                        style={{
                                                            color: 'white',
                                                            width: '14px',
                                                            height: '14px',
                                                            marginLeft: '10px',
                                                            backgroundColor: '#4CAF50',
                                                            borderRadius: '7px',
                                                            padding: '10px',
                                                            paddingTop: '8px',
                                                            paddingBottom: '8px',
                                                            cursor: 'pointer',
                                                            display: isOwner ? 'block' : 'none'
                                                        }}
                                                        onClick={() => handleDeleteField()} />
                                                </>
                                            ) : haveModel === 'S' ? (
                                                <>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <span style={{ marginRight: '10px' }}>{t('field.solarization_model')}</span>
                                                    <InputSwitch
                                                        style={{ transform: 'scale(0.8)' }}
                                                        checked={true}
                                                        onChange={(e) => handleStopSolarization(e.value)}
                                                        disabled={!isOwner} />
                                                </div>
                                                <FontAwesomeIcon
                                                    icon={faTrash}
                                                    style={{
                                                        color: 'white',
                                                        width: '14px',
                                                        height: '14px',
                                                        marginLeft: '10px',
                                                        backgroundColor: '#4CAF50',
                                                        borderRadius: '7px',
                                                        padding: '10px',
                                                        paddingTop: '8px',
                                                        paddingBottom: '8px',
                                                        cursor: 'pointer',
                                                        display: isOwner ? 'block' : 'none'
                                                    }}
                                                    onClick={() => handleDeleteField()} />
                                            </>
                                            ) : (
                                                <>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <span style={{ marginRight: '10px' }}>{t('field.no_model')}</span>
                                                    <InputSwitch
                                                        style={{ transform: 'scale(0.8)' }}
                                                        checked={false}
                                                        onChange={(e) => handleStartModel(e.value)}
                                                        disabled={!isOwner} />
                                                </div>
                                                <FontAwesomeIcon
                                                    icon={faTrash}
                                                    style={{
                                                        color: 'white',
                                                        width: '14px',
                                                        height: '14px',
                                                        marginLeft: '10px',
                                                        backgroundColor: '#4CAF50',
                                                        borderRadius: '7px',
                                                        padding: '10px',
                                                        paddingTop: '8px',
                                                        paddingBottom: '8px',
                                                        cursor: 'pointer',
                                                        display: isOwner ? 'block' : 'none'
                                                    }}
                                                    onClick={() => handleDeleteField()} />
                                            </>
                                            )}
                                        </div>
                                        {isLoadingTrigger ? (
                                            <div className='flex justify-content-center'>
                                                <div className="loading"></div>
                                            </div>
                                        ) : (
                                            <>
                                                <div className='mt-3'>
                                                    {model[0] === 'Nematode active' && licenceStatus && (
                                                        <div className="mb-3">
                                                            {model[1] === 0 && (
                                                                <p className="text-sm mb-2 mt-0 text-color-secondary">{t('field_card.generation').charAt(0).toUpperCase() + t('field_card.generation').slice(1)} {t('field_card.initial')}</p>
                                                            )}
                                                            {model[1] !== 0 && (
                                                                <p className="text-sm mb-2 mt-0 text-color-secondary">{model[1]}º {t('field_card.generation')}</p>
                                                            )}
                                                            <div className="mb-2">
                                                                <div className="flex justify-content-between align-items-center mb-2">
                                                                    <span
                                                                        className={`${model[2] > model[5] ? 'font-medium' : ''} text-color-secondary`}
                                                                        style={model[3] === 'red' ? { animation: 'blinker 1s linear infinite' } : {}}
                                                                    >
                                                                        {t('field_card.egg')}
                                                                    </span>
                                                                    <span
                                                                        className={`${model[2] > model[5] ? 'font-medium' : ''} uppercase text-sm text-color-secondary`}
                                                                        style={model[3] === 'red' ? { animation: 'blinker 1s linear infinite' } : {}}
                                                                    >
                                                                        {model[3] === 'blue' ? t('field_card.wait') : (model[3] === 'orange' ? <><FontAwesomeIcon icon={faExclamationTriangle} style={{ marginRight: '5px' }} />{t('field_card.prepare')}</> : (model[3] === 'red' ? <><FontAwesomeIcon icon={faExclamationTriangle} style={{ marginRight: '5px' }} />{t('field_card.treat')}</> : t('field_card.wait')))}
                                                                    </span>
                                                                </div>
                                                                <div style={{ padding: '3px', backgroundColor: "#DEE2E6" /* getColorFooter(model[3]) */, borderRadius: '50px' }}>
                                                                    <ProgressBar
                                                                        value={model[2] < 10 ? 10 : model[2]}
                                                                        color={getColor(model[3])}
                                                                        showValue={true}
                                                                        displayValueTemplate={() => <span style={{ fontSize: '14px', fontWeight: '500' }}>{`${model[2]} %`}</span>}
                                                                        style={{ borderRadius: '20px', height: '20px', backgroundColor: "#DEE2E6" /* getColorFooter(model[3]) */ }}
                                                                        pt={{
                                                                            value: {
                                                                                className: '',
                                                                                style: { backgroundColor: getColor(model[3]) }
                                                                            }
                                                                        }} />
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className="flex justify-content-between align-items-center mb-2">
                                                                    <span className={`${model[5] > model[2] ? 'font-medium' : ''} text-color-secondary`}>{t('field_card.larvicide')}</span>
                                                                    <span
                                                                        className={`${model[5] > model[2] ? 'font-medium' : ''} uppercase text-sm text-color-secondary`}
                                                                        style={model[6] === 'red' ? { animation: 'blinker 1s linear infinite' } : {}}
                                                                    >
                                                                        {model[6] === 'blue' ? t('field_card.wait') : (model[6] === 'orange' ? <><FontAwesomeIcon icon={faExclamationTriangle} style={{ marginRight: '5px' }} />{t('field_card.prepare')}</> : (model[6] === 'red' ? <><FontAwesomeIcon icon={faExclamationTriangle} style={{ marginRight: '5px' }} />{t('field_card.treat')}</> : t('field_card.wait')))}
                                                                    </span>
                                                                </div>
                                                                <div style={{ padding: '3px', backgroundColor: "#DEE2E6" /* getColorFooter(model[6]) */, borderRadius: '50px' }}>
                                                                    <ProgressBar
                                                                        value={model[5] < 10 ? 10 : model[5]}
                                                                        color={getColor(model[6])}
                                                                        showValue={true}
                                                                        displayValueTemplate={() => <span style={{ fontSize: '14px', fontWeight: '500' }}>{`${model[5]} %`}</span>}
                                                                        style={{ borderRadius: '20px', height: '20px', backgroundColor: "#DEE2E6" /* getColorFooter(model[6]) */ }}
                                                                        pt={{
                                                                            value: {
                                                                                className: '',
                                                                                style: { backgroundColor: getColor(model[6]) }
                                                                            }
                                                                        }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}

                                                    {model[0] === 'Solarization active' && licenceStatus && (
                                                        <div className="mb-3">
                                                            {/* Encabezado con el título y valor actual */}
                                                            <div className="flex justify-content-between align-items-center mb-2">
                                                                <span className="font-medium text-color-secondary">{t('field_card.degrees')}</span>
                                                                <span className="font-medium uppercase text-color-secondary">{model[1] < 50 ? t('field_card.null') :
                                                                    model[1] < 500 ? t('field_card.low') :
                                                                        model[1] < 1500 ? t('field_card.moderate') :
                                                                            model[1] < 3000 ? t('field_card.good') :
                                                                                model[1] < 6000 ? t('field_card.excellent') :
                                                                                    t('field_card.super')}</span>
                                                            </div>
                                                            <div style={{ padding: '3px', backgroundColor: "#DEE2E6" /* getColorCardFooter(model) */, borderRadius: '50px' }}>
                                                                <ProgressBar
                                                                    value={model[1] < 100 ? 13 :
                                                                        model[1] < 1000 ? 16 :
                                                                            model[1] < 1333 ? 19 :
                                                                                model[1] < 1500 ? 21 :
                                                                                    model[1] < 1777 ? 23 :
                                                                                        model[1] < 2000 ? 25 :
                                                                                            model[1] > 7000 ? 100 :
                                                                                                (model[1] / 7000) * 100}
                                                                    color={getColor(model[3])}
                                                                    showValue={true}
                                                                    displayValueTemplate={() => <span style={{ fontSize: '14px', fontWeight: '500', backgroundColor: getColorCard(model) }}>{`${model[1]} ºC`}</span>}
                                                                    style={{ borderRadius: '20px', height: '20px', backgroundColor: "#DEE2E6" /* getColorCardFooter(model) */ }}
                                                                    pt={{
                                                                        value: {
                                                                            className: '',
                                                                            style: { backgroundColor: getColorCard(model) }
                                                                        }
                                                                    }} />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <div className='w-full'>
                                        <div className='flex justify-content-center align-items-center my-3'>
                                            <Calendar
                                                id='init-date'
                                                style={{ height: '30px' }}
                                                value={initDate}
                                                onChange={(e) => {
                                                    setInitDate(e.value);
                                                    fetchChartData(f.id, getModelId(f), e.value, lastDate);
                                                }}
                                                showIcon
                                                dateFormat="dd/mm/yy"
                                                readOnlyInput
                                                className="mr-2" />
                                            <Calendar
                                                id='last-date'
                                                style={{ height: '30px' }}
                                                value={lastDate}
                                                onChange={(e) => {
                                                    setLastDate(e.value);
                                                    fetchChartData(f.id, getModelId(f), initDate, e.value);
                                                }}
                                                showIcon
                                                dateFormat="dd/mm/yy"
                                                readOnlyInput />
                                            <FontAwesomeIcon
                                                icon={faDownload}
                                                style={{
                                                    color: 'white',
                                                    width: '14px',
                                                    height: '14px',
                                                    marginLeft: '10px',
                                                    backgroundColor: '#4CAF50',
                                                    borderRadius: '7px',
                                                    padding: '10px',
                                                    paddingTop: '8px',
                                                    paddingBottom: '8px',
                                                    cursor: 'pointer'
                                                }}
                                                onClick={() => downloadData(model)} />
                                        </div>
                                        {isLoading ? (
                                            <div className='flex justify-content-center'>
                                                <div className="loading"></div>
                                            </div>
                                        ) : (
                                            <Chart className='w-full' style={{ height: '350px' }} type="line" data={chartData} options={multiAxisOptions} />
                                        )}
                                    </div>
                                </>
                            )}
                        </>
                    )}

                    <ModalInterpolate
                        isOpen={isInterpolateModalOpen}
                        onClose={closeInterpolateModal}
                        sensorId={f && f.sensors[0] ? f.sensors[0].id : null} />

                    <ConfirmDialog
                        className="w-11 desktop-max-width border-round-2xl"
                        visible={showStartModelDialog}
                        message={t('field.start_model_message')}
                        header={t('field.adverse')}
                        icon="pi pi-exclamation-triangle"
                        acceptLabel={t('field.nematode_model_button')}
                        rejectLabel={t('field.solarization_model_button')}
                        acceptClassName="bg-[#449E48] w-6"
                        rejectClassName="bg-[#449E48] w-6"
                        defaultFocus="none"
                        focusOnShow={false}
                        onHide={() => setShowStartModelDialog(false)}
                        accept={async () => {
                            setHaveModel('N');
                            await handleStartNematode();
                            setShowStartModelDialog(false);
                        }}
                        reject={async () => {
                            setHaveModel('S');
                            await handleStartSolarization();
                            setShowStartModelDialog(false);
                        }}
                        pt={{
                            header: { className: 'border-round-top-2xl' },
                            footer: {className: 'border-round-bottom-2xl'}
                        }}
                    />

                    <ConfirmDialog
                        className="w-11 desktop-max-width border-round-2xl"
                        visible={showStopNematodeDialog}
                        message={t('field.stop_nematode_message')}
                        header={t('field.adverse')}
                        icon="pi pi-exclamation-triangle"
                        acceptLabel={t('field.confirmDialog')}
                        rejectLabel={t('field.rejectDialog')}
                        acceptClassName="bg-[#449E48] w-3"
                        rejectClassName="bg-[#449E48] w-3"
                        defaultFocus="none"
                        focusOnShow={false}
                        onHide={() => setShowStopNematodeDialog(false)}
                        accept={async () => {
                            setHaveModel('No model');
                            await putNematode(f.id, f.active_prediction_model.id);
                            setRefreshTrigger(prev => !prev);
                            setIsLoadingTrigger(true);
                            setShowStopNematodeDialog(false);
                        }}
                        pt={{
                            header: { className: 'border-round-top-2xl' },
                            footer: {className: 'border-round-bottom-2xl'}
                        }}
                    />

                    <ConfirmDialog
                        className="w-11 desktop-max-width border-round-2xl"
                        visible={showStopSolarizationDialog}
                        message={t('field.stop_solarization_message')}
                        header={t('field.adverse')}
                        icon="pi pi-exclamation-triangle"
                        acceptLabel={t('field.confirmDialog')}
                        rejectLabel={t('field.rejectDialog')}
                        acceptClassName="bg-[#449E48] w-3"
                        rejectClassName="bg-[#449E48] w-3"
                        defaultFocus="none"
                        focusOnShow={false}
                        onHide={() => setShowStopSolarizationDialog(false)}
                        accept={async () => {
                            setHaveModel('No model');
                            await putSolarization(f.id, f.active_solarization.id);
                            setRefreshTrigger(prev => !prev);
                            setIsLoadingTrigger(true);
                            setShowStopSolarizationDialog(false);
                        }}
                        pt={{
                            header: { className: 'border-round-top-2xl' },
                            footer: {className: 'border-round-bottom-2xl'}
                        }}
                    />

                    <ConfirmDialog
                        className="w-11 desktop-max-width border-round-2xl"
                        visible={showDeleteFieldDialog}
                        message={t('field.delete_field_message')}
                        header={t('field.adverse')}
                        acceptLabel={t('field.confirmDialog')}
                        acceptClassName="bg-[#449E48]"
                        rejectClassName="hidden"
                        defaultFocus="none"
                        focusOnShow={false}
                        onHide={() => setShowDeleteFieldDialog(false)}
                        accept={async () => {
                            await deleteField(f.id);
                            setShowDeleteFieldDialog(false);
                            navigate('/fields');
                        }}
                        pt={{
                            header: { className: 'border-round-top-2xl' },
                            footer: {className: 'border-round-bottom-2xl'}
                        }}
                    />

                    <ConfirmDialog
                        className="w-11 desktop-max-width border-round-2xl"
                        visible={showDownloadDataDialog}
                        message={t('field.download_data_message')}
                        header={t('field.download_data')}
                        acceptLabel={t('field.model')}
                        rejectLabel={t('field.temperature')}
                        acceptClassName={model && model[0] === 'No model' ? 'hidden' : 'bg-[#449E48] w-6'}
                        rejectClassName='bg-[#449E48] w-6'
                        defaultFocus="none"
                        focusOnShow={false}
                        onHide={() => setShowDownloadDataDialog(false)}
                        accept={async () => {
                            await downloadModelData(model);
                            setShowDownloadDataDialog(false);
                        }}
                        reject={async () => {
                            await downloadTemperatureData(model);
                            setShowDownloadDataDialog(false);
                        }}
                        pt={{
                            header: { className: 'border-round-top-2xl' },
                            footer: {className: 'border-round-bottom-2xl'}
                        }}
                    />

                    <Dialog 
                        header={<span className='underline text-color-secondary' onClick={() => handleOpenMaps()}>{t('field.open_maps')}</span>}
                        visible={isMapDialogVisible} 
                        onHide={() => setIsMapDialogVisible(false)}
                        className='w-11 p-0 border-round-2xl'
                        pt={{
                            header: { className: 'border-round-top-2xl' },
                            content: {className: 'p-0 border-round-bottom-2xl'}
                        }}
                    >
                        <div className='flex align-items-center justify-content-center w-full map-div'>
                            <Map latitude={lat} longitude={lon} onMapLoad={handleMapLoad} clickable={false} />
                        </div>
                    </Dialog>
                </div>
            </PostLoginWrapper>
        </>
    );
};